<template>
  <div class="ui-orders-row-details">
    <div class="d-flex justify-content-center m-3" v-if="loading">
      <CSpinner color="info" />
    </div>

    <OrdersTableDetailRows
      ref="details"
      :show="!loading"
      :grid="grid"
      :parentData="data"
      :parentRowIndex="parentRowIndex"
      :rows="rows"
      :statusMap="whProgressMap"
      :cols="['product']"
      :actLabel="'pronto'"
      @onActionSelect="onActionSelect"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { Query, Predicate } from "@syncfusion/ej2-data";
import { GetDataManagerNew } from "../../../../../../ds";
import {
  addAndPredicateFilter,
  buildPredicate,
} from "../../../../../../helpers/common";
import {
  INCIDENT_ENTITY_TYPE,
  INCIDENT_TYPE,
  ORDER_ACTION,
  whProgressMap,
} from "../../../../../../config/global";
import EventBus from "../../../../../../helpers/EventBus";
import orderManagementMixin from "../../../share/mixin";
import { filtersMap } from "../../filtersMap";
import OrdersTableDetailRows from "../../../share/OrdersTableDetailRows";

export default {
  name: "ProductsTableRowDetails",

  components: {
    OrdersTableDetailRows,
  },

  mixins: [orderManagementMixin],

  props: {
    grid: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      whProgressMap,
      loading: false,
      rows: [],
      parentRowIndex: null,
    };
  },

  computed: {
    lang: function () {
      return this.$i18n.locale;
    },

    ...mapGetters("connections", ["currencySymbol"]),
  },

  watch: {
    selected(val) {
      EventBus.$emit("orders:detail", {
        parentId: this.data.id,
        selected: [...val],
      });
    },
  },

  mounted() {
    this.bindRowDetailsEvents();
    this.loadData();
  },

  beforeDestroy() {
    this.unbindRowDetailsEvents();
  },

  methods: {
    loadData() {
      const self = this;
      const roleId = this.$store.state.role.id;
      const roundId = this.$store.state.rounds.round.id;
      const ppmId = this.data.ppm.id;
      const orderId = this.data.order;
      const orderStatus = this.data.status;
      const endpoint = filtersMap("wh.panel1.detail.endpoint");
      const dm = GetDataManagerNew(endpoint, [roleId, roundId]);
      const query = this.prepareQuery(ppmId, orderId, orderStatus);
      self.loading = true;

      dm.executeQuery(query)
        .then((response) => {
          self.loading = false;
          self.rows = response.result;

          const parentSelectedIds = self.grid
            .getSelectedRecords()
            .map((item) => item.id);
          if (parentSelectedIds.includes(self.data.id)) {
            this.$nextTick(function () {
              self.$refs.details.selectAll();
            });
          }
        })
        .catch((response) => {
          self.loading = false;
          console.error(response);
        });
    },

    prepareQuery(ppmId, orderId, orderStatus) {
      const { keyword } = this.$store.state.rounds.filters;

      let predicate = new Predicate("ppm.id", "equal", ppmId);
      predicate = addAndPredicateFilter(
        predicate,
        "order.id",
        "equal",
        orderId
      );
      predicate = addAndPredicateFilter(
        predicate,
        "status",
        "equal",
        orderStatus
      );

      const map = filtersMap("wh.panel1.detail.filters");
      predicate = buildPredicate(
        predicate,
        map,
        this.$store.state.rounds.filters
      );

      let query = new Query().where(predicate);

      const searchFields = filtersMap("wh.panel1.detail.search", []);
      if (keyword && searchFields.length > 0) {
        query = query.search(keyword, searchFields);
      }

      return query;
    },

    onActionSelect({ args, row }) {
      const details = [row.id];

      if (args.item.id === ORDER_ACTION.DONE) {
        this.$store
          .dispatch("rounds/updateStatusBatch", {
            status: filtersMap("wh.statusCode.next"),
            details,
          })
          .then(this.onUpdateStatusSuccess);
      }

      if (args.item.id === ORDER_ACTION.UNDONE) {
        this.$store
          .dispatch("rounds/updateStatusBatch", {
            status: filtersMap("wh.statusCode.prev"),
            details,
          })
          .then(this.onUpdateStatusSuccess);
      }

      if (args.item.id === ORDER_ACTION.INCIDENT) {
        this.$store.commit("incidents/createResetAllEntities");
        this.$store.commit("incidents/createAddEntities", {
          entities: [
            { type: INCIDENT_ENTITY_TYPE.DETAILS, items: [{ ...row }] },
          ],
          type: INCIDENT_TYPE.INCIDENT,
          show: true,
        });
      }

      if (args.item.id === ORDER_ACTION.CANCELLATION) {
        this.$store.commit("incidents/createResetAllEntities");
        this.$store.commit("incidents/createAddEntities", {
          entities: [
            { type: INCIDENT_ENTITY_TYPE.DETAILS, items: [{ ...row }] },
          ],
          type: INCIDENT_TYPE.CANCELLATION,
          show: true,
        });
      }
    },
  },
};
</script>
